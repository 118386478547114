.chatbot-icon {
    position: fixed;
    bottom: 5%;
    right: 5%;
    width: 60px;
    height: 60px;
    background-color: #007bff;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  
  .chatbot-icon.open {
    transform: translateX(calc(-100% + 60px));
  }
  
  .icon {
    display: block;
    width: 30px;
    height: 30px;
    margin: 15px;
    background-color: #fff;
    border-radius: 50%;
  }
  