
.topnav {
  overflow: hidden;
  background-color: #f1cccc;
}

.topnav a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #2196F3;
  color: white;
}

.topnav .search-container {
  float: right;
}

.topnav input[type=text] {
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border: none;
}

.topnav .search-container button {
  float: right;
  padding: 9px;
  margin-top: 7px;
  margin-right: 16px;
  margin-left: 5px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.topnav .search-container button:hover {
  background: #007bff;
}

@media screen and (max-width: 600px) {
  .topnav .search-container {
    float: none;
  }
  .topnav a, .topnav input[type=text], .topnav .search-container button {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
  }
  .topnav input[type=text] {
    border: 1px solid #ccc;  
  }
}
.floating-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 48px;
  height: 48px;
  background-color: #f44336;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.floating-icon img {
  width: 24px;
  height: 24px;
  fill: white; 
}

.chatbot-content {
  position: fixed;
  bottom: 20px;
  right: 70px;
  width: 40%;
  height: 70%;
  transition: transform 0.3s;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

}
.question-response-ui{
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  margin-top: 5px;
  height: 44%;
  width:inherit;
}

.question-response-ui::-webkit-scrollbar{
  width: 0;
  height: 0;
}

.chatbot-icon {
  position: absolute;
  float: right;
  bottom: 100%;
  right: 0;
  padding: 10px;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.query-box{
  position: fixed;
  bottom: 30px;
  overflow-x: auto;
  width: 40%;
  bottom: 10px;
}


.query-box input{
  display: inline-block; 
  vertical-align: middle;
  padding: 10px;
  width: 85%;
  margin-bottom: 20px;

}

.query-box img{
  float: right;
  display: inline-block; 
  vertical-align: middle;
  width: 30px;
  height: 30px;
}

.questions{
  font-size: 15px;
  width: max-content;
  border: 2px solid #2196F3;
  background-color: #2196F3;
  border-radius: 10px;
  padding: 10px;
}

.responses{
  font-size: 15px;
  width: max-content;
  border: 2px solid #21f3b8;
  background-color: #21f3b8;
  border-radius: 10px;
  right: 10px; 
  margin-right: 10% !important;
  padding: 10px;
}